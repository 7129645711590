<template>
  <ModuleView>
    <template v-slot:buttons>
      <ButtonCreate
        v-if="isCreateButtonVisible()"
        route-name="contentBlock_new"
      >
      </ButtonCreate>
    </template>
    <template v-slot:body>
      <app-filter></app-filter>
      <div class="card">
        <div class="card-body">
          <Preloader v-if="callingAPI"/>
          <div v-if="callingAPI === false && list.length == 0" class="m-t-10">
            <p class="alert alert-info">
              {{ $t('result_not_found') }}
            </p>
          </div>
          <div class="table-responsive" v-if="list.length > 0">
            <table id="list-table" class="table table-striped">
              <thead>
              <tr>
                <th class="hidden-sm-down">
                  {{ $t("contentBlock.list.id") }}
                </th>
                <th width="200">
                  {{ $t("contentBlock.list.title") }}
                </th>
                <th>
                  {{ $t("contentBlock.list.type") }}
                </th>
                <th>
                  {{ $t("contentBlock.list.identifier") }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("contentBlock.list.hidden") }}
                </th>
                <th class="hidden-sm-down" width="80">
                  {{ $t("contentBlock.list.number_of_items") }}
                </th>
                <th width="60">
                  {{ $t("contentBlock.list.external_fallback") }}
                </th>
                <th class="hidden-sm-down" width="80">
                  {{ $t("contentBlock.list.internal_fallback") }}
                </th>
                <th class="hidden-sm-down" width="450">
                  {{ $tc('contentBlock.site') }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("contentBlock.list.position") }}
                </th>
                <th class="hidden-sm-down" width="200">
                  {{ $t("contentBlock.list.created_at") }}
                </th>
                <th class="hidden-sm-down" width="200">
                  {{ $t("contentBlock.list.modified_at") }}
                </th>
                <th width="180">
                  {{ $t("actions") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(contentBlock, index) in list" :key="`item-${index}`">
                <td class="hidden-sm-down">
                  {{ contentBlock.id }}
                </td>
                <td>
                  {{ contentBlock.title }}
                </td>
                <td>
                  {{ contentBlock.type }}
                </td>
                <td>
                  {{ contentBlock.identifier }}
                </td>
                <td class="hidden-sm-down">
                  <i v-if="contentBlock.hidden" class="fas fa-check-circle"></i>
                  <i v-else class="fas fa-times-circle"></i>
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.NumberOfItems }}
                </td>
                <td>
                  <template v-if="contentBlock.FallbackArticleType">
                    <i v-if="contentBlock.FallbackArticleType.external" class="fas fa-check-circle"></i>
                    <i v-else class="fas fa-times-circle"></i>
                  </template>
                </td>
                <td class="hidden-sm-down">
                  <template v-if="contentBlock.FallbackArticleType">
                    <i v-if="contentBlock.FallbackArticleType.article" class="fas fa-check-circle"></i>
                    <i v-else class="fas fa-times-circle"></i>
                  </template>
                </td>
                <td class="hidden-sm-down">
                  {{ siteById(contentBlock.siteId) }}
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.position }}
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.createdAt | prettyDateTime }}<br>
                  {{ userNameById(contentBlock.createdBy) }}
                </td>
                <td class="hidden-sm-down">
                  {{ contentBlock.modifiedAt | prettyDateTime }}<br>
                  {{ userNameById(contentBlock.modifiedBy) }}
                </td>
                <td>
                  <router-link
                    tag="a"
                    class="btn btn-default btn-sm"
                    :to="{ name: 'contentBlock_detail', params: { id: contentBlock.id }}"
                    data-test="contentBlock_list_btn_detail"
                  >
                    <i class="fa fa-info"></i>
                  </router-link>
                  <router-link
                    v-if="isEditButtonVisible(contentBlock)"
                    tag="a"
                    class="btn btn-default btn-sm"
                    :to="{ name: 'contentBlock_edit', params: { id: contentBlock.id }}"
                    data-test="contentBlock_list_btn_edit"
                  >
                    <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <p class="m-t-20">{{ $t('found') }}: {{ totalCount }}</p>
            </div>
            <div class="col-lg-6">
              <div id="editable-datatable_wrapper" class="dataTables_wrapper" v-if="list.length > 0">
                <div class="dataTables_paginate paging_simple_numbers" id="editable-datatable_paginate">
                  <Paginate
                    v-model="currentPage"
                    :page-count="pageCount"
                    :page-range="5"
                    :margin-pages="2"
                    :click-handler="setPageAndGetRecords"
                    :prev-text="$t('page_prev')"
                    :next-text="$t('page_next')"
                    :container-class="'pagination'"
                    :prev-class="'paginate_button previous'"
                    :next-class="'paginate_button next'"
                    :page-class="'paginate_button'"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ModuleView>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '@/components/preloader/Preloader'
import Filter from '@/components/filter/FilterContentBlock'
import Paginate from 'vuejs-paginate'
import ButtonCreate from '@/components/shared/ButtonCreate'
import Config from '@/config'
import UserService from '@/services/user/UserService'
import PermissionService from '@/services/PermissionService'
import { MODULE_SYSTEM_CONTENT_BLOCK } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'ContentBlockList',
  data () {
    return {
      currentPage: 1,
      limit: Config.contentBlock.list.limit,
      dataLoaded: false,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_CONTENT_BLOCK_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    sites () {
      return this.$store.getters['site/enabledSitesForForm'](MODULE_SYSTEM_CONTENT_BLOCK, this.list)
    },
    list () {
      return this.$store.getters['contentBlock/list']
    },
    totalCount () {
      return this.$store.getters['contentBlock/totalCount']
    },
    page () {
      return this.$store.getters['contentBlock/page']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['contentBlock/totalCount'] / Config.contentBlock.list.limit)
    }
  },
  components: {
    ModuleView,
    Preloader,
    appFilter: Filter,
    Paginate,
    ButtonCreate
  },
  methods: {
    siteById (id) {
      return this.$store.getters['site/siteById'](id)?.title
    },
    hasPermission (permission, contentBlock) {
      return this.$store.getters['user/hasPermission'](permission, contentBlock?.site ?? null)
    },
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible (contentBlock) {
      return this.hasPermission(this.requiredPermissions.editButton, contentBlock)
    },
    async getList () {
      await this.$store.dispatch('contentBlock/fetch')
        .then(async () => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('contentBlock/setPage', page)
      this.getList()
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    }
  },
  mounted () {
    this.$store.commit('contentBlock/setLimit', Config.contentBlock.list.limit)
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
  .table-responsive {
    th, td {
      font-size: 14px
    }
  }
</style>
